import React, { useContext, useEffect, useState } from "react"
import dayjs from "dayjs"
import { DataContext } from "../context/DataContext"
import {
  Grid,
  Paper,
  Box,
  IconButton,
  Button,
  useMediaQuery,
} from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import SaveIcon from "@mui/icons-material/Save"
import { FormComponent, useForm } from "./useForm"
import Controls from "./controls/Controls"
import { insertData, updateData, deleteData } from "../backend/server"
import { ToastContainer } from "react-toastify"
import { successNotify, errorNotify } from "./Alert"
import { useNavigate } from "react-router-dom"
import Modal from "./controls/Modal"
import { useTheme } from "@mui/material/styles"

// Valori iniziali del form
const initialFValues = {
  id: 0,
  date: "",
  done: "",
}

// Definizione dei tipi di allenamenti
const typeItems = [
  { id: "Fatto", title: "Fatto" },
  { id: "Da Fare", title: "Da Fare" },
]

const TrainingForm = ({ text, setText, id, presences, players, idTeam }) => {
  const { values, setValues, handleInputChange } = useForm(initialFValues)
  const [open, setOpen] = useState(false)
  const { trainings, teamId, loading, error } = useContext(DataContext)
  const navigate = useNavigate()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const trainingData = trainings.find((item) => item.id === id)

  useEffect(() => {
    if (id !== "new" && trainingData) {
      setValues({
        id: trainingData.id,
        date: dayjs(trainingData.date),
        done: trainingData.done,
      })
    }
  }, [id, trainingData, setValues])

  const formValidation = () => {
    return values.date !== "" && values.done !== ""
  }

  const handleDateChange = (date) => {
    setValues({
      ...values,
      date: date ? dayjs(date).format("YYYY-MM-DD") : "",
    })
  }

  const save = async () => {
    if (!formValidation()) {
      errorNotify("Compila tutti i campi!")
      return
    }
    if (typeof values.date === "object") {
      values.date = dayjs(values.date, "DD/MM/YYYY").format("YYYY-MM-DD")
    }
    const object = {
      date: values.date,
      done: values.done,
      presences: JSON.stringify(presences),
      team_id: idTeam ? idTeam : teamId,
    }
    const isNew = id === "new"
    let result
    if (isNew) {
      result = await insertData("trainings", object, trainings)
    } else {
      result = await updateData("trainings", object, id, trainings)
    }
    if (result) {
      successNotify()
      if (isNew) navigate(`/training/${result}`)
    } else {
      errorNotify()
    }
  }

  const deleteTraining = async () => {
    const result = await deleteData("trainings", id, trainings)
    if (result) {
      successNotify()
      navigate("/trainings")
    } else {
      errorNotify()
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: { xs: 1, sm: 2 },
      }}
    >
      <ToastContainer />

      {/* Bottone Indietro e Salva visibili solo su mobile */}
      {isMobile ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <IconButton onClick={() => navigate("/trainings")}>
            <ArrowBackIcon />
          </IconButton>
          <Button variant="contained" color="primary" onClick={save}>
            Salva
          </Button>
        </Box>
      ) : null}

      <Paper
        elevation={5}
        sx={{
          padding: { xs: 2, sm: 4 },
          borderRadius: 3,
          width: "100%",
        }}
      >
        <Controls.Text variant="h5" gutterBottom text="Dati Allenamento" />
        <FormComponent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Controls.DateInput
                name="date"
                label="Data"
                value={values.date}
                onChange={handleDateChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Select
                name="done"
                label="Fatto/Da Fare"
                value={values.done}
                onChange={handleInputChange}
                options={typeItems}
              />
            </Grid>

            {/* Bottone Salva ed Elimina visibili su tutti i dispositivi */}
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Grid container spacing={1} justifyContent="center">
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <Controls.Button
                    text="Salva"
                    size="medium"
                    fullWidth
                    sx={{
                      backgroundColor: "primary.main",
                      "&:hover": {
                        backgroundColor: "primary.dark",
                      },
                    }}
                    onClick={save}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <Controls.Button
                    text="Elimina"
                    size="medium"
                    fullWidth
                    color="error"
                    onClick={() => setOpen(true)}
                    sx={{
                      "&:hover": {
                        backgroundColor: "error.dark",
                        color: "#fff",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Modal
                open={open}
                onClose={() => setOpen(false)}
                onConfirm={deleteTraining}
                title="Conferma eliminazione"
                text="Sei sicuro di voler eliminare l'allenamento?"
              />
            </Grid>
          </Grid>
        </FormComponent>
      </Paper>
    </Box>
  )
}

export default TrainingForm
