import React, { useContext, useEffect, useState } from "react"
import dayjs from "dayjs"
import { DataContext } from "../context/DataContext"
import Box from "@mui/material/Box"
import Datatable from "../components/Datatable"
import { useNavigate } from "react-router-dom"
import { Grid, CircularProgress, IconButton } from "@mui/material"
import Controls from "../components/controls/Controls"
import { useForm } from "../components/useForm"
import EditIcon from "@mui/icons-material/Edit"

const Matches = () => {
  const {
    matches,
    teams,
    loading,
    error,
    teamId,
    selectedTeam,
    setSelectedTeam,
  } = useContext(DataContext)
  const [matchesDataTable, setMatchesDataTable] = useState([])
  const [dataLoaded, setDataLoaded] = useState(false) // Stato per gestire il caricamento dei dati
  const navigate = useNavigate()
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600)

  // Imposta le opzioni del selettore, escludendo il team con id 8
  const teamsOptions = teams
    .filter((team) => team.id !== "8") // Filtra per escludere l'id 8
    .map((team) => ({
      id: team.id,
      title: team.name,
    }))

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600)
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  // Effetto per aggiornare i dati della tabella quando i dati degli impegni cambiano
  useEffect(() => {
    if (matches) {
      const filteredMatches = selectedTeam
        ? matches
            .filter((item) => item.team_id === selectedTeam)
            .sort((a, b) => new Date(a.date) - new Date(b.date)) // Converti le stringhe delle date in oggetti Date
        : matches.sort((a, b) => new Date(b.date) - new Date(a.date))

      setMatchesDataTable(
        filteredMatches.map((item) => [
          item.id,
          dayjs(item.date).format("DD/MM/YYYY"),
          item.type,
          item.place,
          item.meetingHour,
          item.hour,
        ])
      )
      setDataLoaded(true) // Imposta su true quando i dati sono pronti
    }
  }, [matches, selectedTeam, teamId])

  if (loading)
    return (
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 2 }}
        style={{
          marginTop: 30,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    )

  if (error) return <div>Error: {error}</div>

  const columns = [
    { name: "ID", options: { display: false } },
    {
      name: "Data",
      options: {
        customBodyRender: (value) => <p className="capitalize">{value}</p>,
      },
    },
    {
      name: "Tipo",
      options: {
        customBodyRender: (value) => (
          <p
            className={`capitalize px-3 py-1 inline-block rounded-full text-white font-bold ${
              value === "Amichevole"
                ? "bg-red-500"
                : value === "Torneo"
                ? "bg-green-500"
                : value === "Campionato"
                ? "bg-yellow-500"
                : "bg-blue-500"
            }`}
          >
            {value}
          </p>
        ),
      },
    },
    {
      name: "Luogo",
      options: {
        customBodyRender: (value) => <p className="capitalize">{value}</p>,
        display: isMobile ? "false" : "true",
      },
    },
    {
      name: "Ora Ritrovo",
      options: {
        customBodyRender: (value) => <p className="capitalize">{value}</p>,
        display: isMobile ? "false" : "true",
      },
    },
    {
      name: "Ora",
      options: {
        customBodyRender: (value) => <p className="capitalize">{value}</p>,
        display: isMobile ? "false" : "true",
      },
    },
    {
      name: "Azioni",
      options: {
        customBodyRender: (value, tableMeta) => (
          <Box
            sx={{
              display: "flex",
              gap: 1,
              justifyContent: "flex-end",
              flexWrap: "wrap",
            }}
          >
            <IconButton
              color="primary"
              onClick={() =>
                navigate(`/match/${tableMeta.rowData[0]}`, {
                  state: { team: selectedTeam },
                })
              }
            >
              <EditIcon />
            </IconButton>
          </Box>
        ),
        setCellProps: () => ({
          sx: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
            flexWrap: "wrap",
          },
        }),
      },
    },
  ]

  const changeSelectedTeam = (e) => {
    setSelectedTeam(e.target.value)
  }

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 2 }}
      style={{ marginTop: 30, width: "100%" }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          {teamId === "8" && (
            <Controls.Select
              name="selectedTeam"
              label="Squadra"
              value={selectedTeam}
              onChange={changeSelectedTeam}
              options={teamsOptions}
              width="100%"
            />
          )}
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          {(teamId !== "8" || (teamId === "8" && selectedTeam)) && (
            <Controls.Button
              text="Aggiungi Impegno"
              color="success"
              onClick={() =>
                navigate("/match/new", {
                  state: { team: selectedTeam },
                })
              }
              sx={{ mt: 2 }}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <div>
            {(dataLoaded && teamId === "8" && selectedTeam) ||
            teamId !== "8" ? (
              <Datatable data={matchesDataTable || []} columns={columns} />
            ) : (
              <Box
                component="main"
                sx={{ flexGrow: 1, p: 3 }}
                style={{
                  marginTop: 30,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Controls.Text
                  variant="h5"
                  gutterBottom
                  text="Seleziona una squadra per visualizzare gli impegni"
                />
              </Box>
            )}
          </div>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Matches
