import React, { useContext, useEffect, useState } from "react"
import dayjs from "dayjs"
import { DataContext } from "../context/DataContext"
import {
  Grid,
  Paper,
  Box,
  Divider,
  IconButton,
  useMediaQuery,
  Button,
} from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import SaveIcon from "@mui/icons-material/Save"
import { FormComponent, useForm } from "./useForm"
import Controls from "./controls/Controls"
import * as playerService from "../services/playersService"
import { useNavigate } from "react-router-dom"
import Modal from "./controls/Modal"
import { insertData, updateData, deleteData } from "../backend/server"
import { ToastContainer } from "react-toastify"
import { successNotify, errorNotify } from "./Alert"
import { useTheme } from "@mui/material/styles"

const initialFValues = {
  id: 0,
  name: "",
  surname: "",
  birthdate: "",
  role: "",
  weightS: "",
  weightD: "",
  weightJ: "",
  heightS: "",
  heightD: "",
  heightJ: "",
  sitHeightS: "",
  sitHeightD: "",
  sitHeightJ: "",
  chairHeightS: "",
  chairHeightD: "",
  chairHeightJ: "",
}

const PlayerForm = ({ id, idTeam }) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const [open, setOpen] = useState(false)
  const { values, setValues, handleInputChange } = useForm(initialFValues)
  const { players, teamId, loading, error } = useContext(DataContext)

  const playerData = players.find((item) => item.id === id)

  useEffect(() => {
    if (id !== "new" && playerData) {
      setValues({
        id: playerData.id,
        name: playerData.name,
        surname: playerData.surname,
        birthdate: dayjs(playerData.birthdate),
        role: playerData.role,
        weightS: playerData.weightS || "",
        weightD: playerData.weightD || "",
        weightJ: playerData.weightJ || "",
        heightS: playerData.heightS || "",
        heightD: playerData.heightD || "",
        heightJ: playerData.heightJ || "",
        sitHeightS: playerData.sitHeightS || "",
        sitHeightD: playerData.sitHeightD || "",
        sitHeightJ: playerData.sitHeightJ || "",
        chairHeightS: playerData.chairHeightS || "",
        chairHeightD: playerData.chairHeightD || "",
        chairHeightJ: playerData.chairHeightJ || "",
      })
    }
  }, [id, playerData, setValues])

  const handleDateChange = (date) => {
    setValues({
      ...values,
      birthdate: date ? dayjs(date, "DD/MM/YYYY").format("YYYY-MM-DD") : "",
    })
  }

  const formValidation = () => {
    if (values.name === "" || values.surname === "" || values.role === "") {
      return false
    }
    return true
  }

  const save = async () => {
    if (!formValidation()) {
      errorNotify("Compila tutti i campi!")
      return
    }
    if (typeof values.birthdate === "object") {
      values.birthdate = dayjs(values.birthdate, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      )
    }

    const object = {
      name: values.name,
      surname: values.surname,
      birthdate: values.birthdate,
      role: values.role,
      weightS: values.weightS || null,
      weightD: values.weightD || null,
      weightJ: values.weightJ || null,
      heightS: values.heightS || null,
      heightD: values.heightD || null,
      heightJ: values.heightJ || null,
      sitHeightS: values.sitHeightS || null,
      sitHeightD: values.sitHeightD || null,
      sitHeightJ: values.sitHeightJ || null,
      chairHeightS: values.chairHeightS || null,
      chairHeightD: values.chairHeightD || null,
      chairHeightJ: values.chairHeightJ || null,
      team_id: idTeam ? idTeam : teamId,
    }

    const isNew = id === "new"
    let result

    if (isNew) {
      result = await insertData("players", object, players)
    } else {
      result = await updateData("players", object, id, players)
    }

    if (!!result) {
      successNotify()
      if (isNew) navigate(`/player/${result}`)
    } else {
      errorNotify()
    }
  }

  const deletePlayer = async () => {
    const result = await deleteData("players", id, players)
    if (!!result) {
      successNotify()
      navigate("/")
    } else {
      errorNotify()
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: { xs: 1, sm: 2 },
      }}
    >
      <ToastContainer />
      {isMobile ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <IconButton onClick={() => navigate("/")}>
            <ArrowBackIcon />
          </IconButton>
          <Button variant="contained" color="primary" onClick={save}>
            Salva
          </Button>
        </Box>
      ) : null}

      <Paper
        elevation={5}
        sx={{
          padding: { xs: 2, sm: 4 },
          borderRadius: 3,
          width: "100%",
        }}
      >
        <Controls.Text variant="h5" gutterBottom text="Dati Giocatore" />
        <FormComponent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="name"
                label="Nome"
                value={values.name}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="surname"
                label="Cognome"
                value={values.surname}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Select
                name="role"
                label="Ruolo"
                value={values.role}
                onChange={handleInputChange}
                options={playerService.getRolesCollection()}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.DateInput
                name="birthdate"
                label="Data di Nascita"
                value={values.birthdate}
                onChange={handleDateChange}
              />
            </Grid>
            {/* Altri campi del form */}
            <Grid item xs={12} md={12}>
              <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="weightS"
                label="Peso Settembre (kg)"
                value={values.weightS}
                onChange={handleInputChange}
                type="number"
                inputProps={{ min: "0", step: "0.5" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="heightS"
                label="Altezza Settembre (cm)"
                value={values.heightS}
                onChange={handleInputChange}
                type="number"
                inputProps={{ min: "0", step: "0.5" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="sitHeightS"
                label="Altezza Seduta Settembre (cm)"
                value={values.sitHeightS}
                onChange={handleInputChange}
                type="number"
                inputProps={{ min: "0", step: "0.5" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="chairHeightS"
                label="Altezza Sedia Settembre (cm)"
                value={values.chairHeightS}
                onChange={handleInputChange}
                type="number"
                inputProps={{ min: "0", step: "0.5" }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="weightD"
                label="Peso Dicembre (kg)"
                value={values.weightD}
                onChange={handleInputChange}
                type="number"
                inputProps={{ min: "0", step: "0.5" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="heightD"
                label="Altezza Dicembre (cm)"
                value={values.heightD}
                onChange={handleInputChange}
                type="number"
                inputProps={{ min: "0", step: "0.5" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="sitHeightD"
                label="Altezza Seduta Dicembre (cm)"
                value={values.sitHeightD}
                onChange={handleInputChange}
                type="number"
                inputProps={{ min: "0", step: "0.5" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="chairHeightD"
                label="Altezza Sedia Dicembre (cm)"
                value={values.chairHeightD}
                onChange={handleInputChange}
                type="number"
                inputProps={{ min: "0", step: "0.5" }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="weightJ"
                label="Peso Giugno (kg)"
                value={values.weightJ}
                onChange={handleInputChange}
                type="number"
                inputProps={{ min: "0", step: "0.5" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="heightJ"
                label="Altezza Giugno (cm)"
                value={values.heightJ}
                onChange={handleInputChange}
                type="number"
                inputProps={{ min: "0", step: "0.5" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="sitHeightJ"
                label="Altezza Seduta Giugno (cm)"
                value={values.sitHeightJ}
                onChange={handleInputChange}
                type="number"
                inputProps={{ min: "0", step: "0.5" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="chairHeightJ"
                label="Altezza Sedia Giugno (cm)"
                value={values.chairHeightJ}
                onChange={handleInputChange}
                type="number"
                inputProps={{ min: "0", step: "0.5" }}
              />
            </Grid>

            {/* Bottone Salva ed Elimina visibili su tutti i dispositivi */}
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Grid container spacing={1} justifyContent="center">
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <Controls.Button
                    text="Salva"
                    size="medium"
                    fullWidth
                    sx={{
                      backgroundColor: "primary.main",
                      "&:hover": {
                        backgroundColor: "primary.dark",
                      },
                    }}
                    onClick={save}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <Controls.Button
                    text="Elimina"
                    size="medium"
                    fullWidth
                    color="error"
                    onClick={() => setOpen(true)}
                    sx={{
                      "&:hover": {
                        backgroundColor: "error.dark",
                        color: "#fff",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Modal
              open={open}
              onClose={() => setOpen(false)}
              onConfirm={deletePlayer}
              title="Conferma eliminazione"
              text="Sei sicuro di voler eliminare il giocatore?"
            />
          </Grid>
        </FormComponent>
      </Paper>
    </Box>
  )
}

export default PlayerForm
