import React, { useContext, useState, useEffect } from "react"
import dayjs from "dayjs"
import { DataContext } from "../context/DataContext"
import Box from "@mui/material/Box"
import LinearProgress from "@mui/material/LinearProgress"
import Datatable from "../components/Datatable"
import { useNavigate } from "react-router-dom"
import { Grid, CircularProgress, IconButton } from "@mui/material"
import Controls from "../components/controls/Controls"
import EditIcon from "@mui/icons-material/Edit"

const Trainings = () => {
  const {
    trainings,
    teams,
    players,
    teamId,
    loading,
    error,
    selectedTeam,
    setSelectedTeam,
  } = useContext(DataContext)
  const [trainingsDataTable, setTrainingsDataTable] = useState([])
  const [dataLoaded, setDataLoaded] = useState(false) // Stato per gestire il caricamento dei dati
  const navigate = useNavigate()
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600)

  const teamsOptions = teams
    .filter((team) => (teamId === "8" ? team.id !== "8" : true)) // Filtra solo se teamId è "8"
    .map((team) => ({
      id: team.id,
      title: team.name,
    }))

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600)
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    if (trainings) {
      const filteredTrainings = selectedTeam
        ? trainings
            .filter((item) => item.team_id === selectedTeam)
            .sort((a, b) => new Date(a.date) - new Date(b.date)) // Converti le stringhe delle date in oggetti Date
        : trainings.sort((a, b) => new Date(b.date) - new Date(a.date))

      const filteredPlayers = selectedTeam
        ? players.filter((item) => item.team_id === selectedTeam)
        : players

      const playerCount =
        filteredPlayers.length > 0 ? filteredPlayers.length : 1 // Imposta a 1 per evitare divisione per zero

      setTrainingsDataTable(
        filteredTrainings.map((item) => {
          const presentCount = Object.values(JSON.parse(item.presences)).filter(
            (p) => p === "Presente"
          )
          const presenceRatio = `${presentCount.length}/${playerCount}`

          return [
            item.id,
            dayjs(item.date).format("DD/MM/YYYY"),
            item.done,
            presenceRatio,
          ]
        })
      )
      setDataLoaded(true) // Imposta su true quando i dati sono pronti
    }
  }, [trainings, selectedTeam, players])

  if (loading)
    return (
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3 }}
        style={{
          marginTop: 30,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    )

  if (error) return <div>Error: {error}</div>

  const columns = [
    { name: "ID", options: { display: false } },
    {
      name: "Data",
      options: {
        customBodyRender: (value) => <p className="capitalize">{value}</p>,
      },
    },
    {
      name: "Fatto/Da fare",
      options: {
        customBodyRender: (value) => (
          <p
            className={`capitalize px-3 py-1 inline-block rounded-full text-white font-bold ${
              value === "Fatto" ? "bg-green-500" : "bg-red-500"
            }`}
            style={{ fontSize: "0.9rem" }} // Riduci la dimensione del testo se necessario
          >
            {value}
          </p>
        ),
        setCellProps: () => ({
          style: {
            minWidth: "120px", // Aumenta la larghezza minima per una migliore visibilità
          },
        }),
      },
    },
    {
      name: "Presenze",
      options: {
        display: isMobile ? "false" : "true",
        customBodyRender: (value) => {
          const [presenti, totale] = value.split("/").map(Number)
          const percentuale = (presenti / totale) * 100

          return (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap", // Assicura che i contenuti si adattino ai piccoli schermi
              }}
            >
              <Box sx={{ flexGrow: 1, minWidth: "70px" }}>
                {" "}
                {/* Aumenta la larghezza minima */}
                <LinearProgress
                  variant="determinate"
                  value={percentuale}
                  sx={{
                    height: 8,
                    borderRadius: 5,
                    backgroundColor: "#e0e0e0",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "primary.main",
                    },
                  }}
                />
              </Box>
              <span style={{ fontWeight: "bold", marginLeft: 8 }}>{value}</span>
            </Box>
          )
        },
        setCellProps: () => ({
          style: {
            minWidth: "120px", // Aumenta la larghezza minima per una migliore visibilità
          },
        }),
      },
    },
    {
      name: "Azioni",
      options: {
        customBodyRender: (value, tableMeta) => (
          <Box
            sx={{
              display: "flex",
              gap: 1,
              justifyContent: "flex-end",
              flexWrap: "wrap",
            }}
          >
            <IconButton
              color="primary"
              onClick={() =>
                navigate(`/training/${tableMeta.rowData[0]}`, {
                  state: { team: selectedTeam },
                })
              }
            >
              <EditIcon />
            </IconButton>
          </Box>
        ),
        setCellProps: () => ({
          sx: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
            flexWrap: "wrap",
          },
        }),
      },
    },
  ]

  const changeSelectedTeam = (e) => {
    setSelectedTeam(e.target.value)
  }

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 3 }}
      style={{ marginTop: 30, width: "100%" }}
    >
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          {teamId === "8" && (
            <Controls.Select
              name="selectedTeam"
              label="Squadra"
              value={selectedTeam}
              onChange={changeSelectedTeam}
              options={teamsOptions}
              width="100%"
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{ display: "flex", justifyContent: "right" }}
        >
          {(teamId !== "8" || (teamId === "8" && selectedTeam)) && (
            <Controls.Button
              text="Aggiungi Allenamento"
              color="success"
              onClick={() =>
                navigate("/training/new", {
                  state: { team: selectedTeam },
                })
              }
            />
          )}
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          {(dataLoaded && teamId === "8" && selectedTeam) || teamId !== "8" ? (
            <Datatable
              title="Allenamenti"
              data={trainingsDataTable || []}
              columns={columns}
            />
          ) : (
            <Box
              component="main"
              sx={{ flexGrow: 1, p: 3 }}
              style={{
                marginTop: 30,
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Controls.Text
                variant="h5"
                gutterBottom
                text="Seleziona una squadra per visualizzare gli allenamenti"
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default Trainings
