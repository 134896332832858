import React, { useContext, useEffect, useState } from "react"
import dayjs from "dayjs"
import { DataContext } from "../context/DataContext"
import {
  Grid,
  Paper,
  Box,
  Divider,
  IconButton,
  Button,
  Typography, // Importa Typography
  useMediaQuery,
  Tooltip,
  Checkbox,
} from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { FormComponent, useForm } from "./useForm"
import Controls from "./controls/Controls"
import { insertData, updateData, deleteData } from "../backend/server"
import { ToastContainer } from "react-toastify"
import { successNotify, errorNotify } from "./Alert"
import { useNavigate } from "react-router-dom"
import Modal from "./controls/Modal"
import localizedFormat from "dayjs/plugin/localizedFormat"
import "dayjs/locale/it" // Importa la lingua italiana
import { useTheme } from "@mui/material/styles"

// Configura dayjs con il plugin di localizzazione
dayjs.extend(localizedFormat)

const typeItems = [
  { id: "Amichevole", title: "Amichevole" },
  { id: "Torneo", title: "Torneo" },
  { id: "Campionato", title: "Campionato" },
  { id: "Liga", title: "Liga" },
]

const initialFValues = {
  id: 0,
  date: "",
  type: "",
  title: "",
  place: "",
  hour: "",
  meetingHour: "",
  opponents: "",
  meal: "",
  numMatches: 1,
  selectedPlayers: [],
  doubleGroup: false,
  titleG2: "",
  placeG2: "",
  hourG2: "",
  meetingHourG2: "",
  opponentsG2: "",
  numMatchesG2: 0,
  selectedPlayersG2: [],
}

const MatchForm = ({ text, setText, id, idTeam }) => {
  const { values, setValues, handleInputChange } = useForm(initialFValues)
  const [open, setOpen] = useState(false)
  const { matches, players, teamId, loading, error } = useContext(DataContext)
  const navigate = useNavigate()
  const [playersOptions, setPlayersOptions] = useState([])
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const matchData = matches.find((item) => item.id === id)

  useEffect(() => {
    const filteredPlayers = idTeam
      ? players
          .filter((item) => item.team_id === idTeam)
          .sort((a, b) => a.surname.localeCompare(b.surname))
      : players.sort((a, b) => a.surname.localeCompare(b.surname))

    const playerOptions = filteredPlayers.map((player) => ({
      id: player.id,
      player: `${player.surname.toUpperCase()} ${player.name}`,
    }))

    if (id !== "new" && matchData) {
      setValues({
        id: matchData.id,
        date: dayjs(matchData.date),
        type: matchData.type,
        title: matchData.title,
        place: matchData.place,
        hour: matchData.hour,
        meetingHour: matchData.meetingHour,
        opponents: matchData.opponents,
        meal: matchData.meal,
        numMatches: matchData.numMatches,
        selectedPlayers: JSON.parse(matchData.calledups),
        doubleGroup: parseInt(matchData.doubleGroup) === 1 ? true : false,
        titleG2: matchData.titleG2,
        placeG2: matchData.placeG2,
        hourG2: matchData.hourG2,
        meetingHourG2: matchData.meetingHourG2,
        opponentsG2: matchData.opponentsG2,
        selectedPlayersG2: JSON.parse(matchData.calledupsG2),
        numMatchesG2: matchData.numMatchesG2,
      })
    }
    setPlayersOptions(playerOptions)
  }, [id, matchData, setValues, idTeam, players])

  const formValidation = () => {
    return (
      values.date !== "" &&
      values.type !== "" &&
      values.place !== "" &&
      values.hour !== "" &&
      values.meetingHour !== ""
    )
  }

  const handleDateChange = (date) => {
    setValues({
      ...values,
      date: date ? dayjs(date).format("YYYY-MM-DD") : "",
    })
  }

  const save = async () => {
    if (!formValidation()) {
      errorNotify("Compila tutti i campi!")
      return
    }
    if (typeof values.date === "object") {
      values.date = dayjs(values.date, "DD/MM/YYYY").format("YYYY-MM-DD")
    }
    const object = {
      date: values.date,
      type: values.type,
      title: values.title,
      place: values.place,
      hour: values.hour,
      meetingHour: values.meetingHour,
      calledups: JSON.stringify(values.selectedPlayers),
      opponents: values.opponents,
      meal: values.meal,
      numMatches: values.type === "Torneo" ? values.numMatches : 1,
      doubleGroup: values.doubleGroup ? 1 : 0,
      team_id: idTeam ? idTeam : teamId,
      titleG2: values.doubleGroup ? values.titleG2 : "",
      placeG2: values.doubleGroup ? values.placeG2 : "",
      hourG2: values.doubleGroup ? values.hourG2 : "",
      meetingHourG2: values.doubleGroup ? values.meetingHourG2 : "",
      opponentsG2: values.doubleGroup ? values.opponentsG2 : "",
      calledupsG2: values.doubleGroup
        ? JSON.stringify(values.selectedPlayersG2)
        : JSON.stringify([]),
      numMatchesG2: values.doubleGroup
        ? values.type === "Torneo"
          ? values.numMatchesG2
          : 1
        : 0,
    }

    const isNew = id === "new"
    let result
    if (isNew) {
      result = await insertData("matches", object, matches)
    } else {
      result = await updateData("matches", object, id, matches)
    }
    if (!!result) {
      successNotify()
      if (isNew) navigate(`/match/${result}`)
    } else {
      errorNotify()
    }
  }

  const deleteMatch = async () => {
    const result = await deleteData("matches", id, matches)
    if (!!result) {
      successNotify()
      navigate("/matches")
    } else {
      errorNotify()
    }
  }

  const formatDateWithCapitalMonth = (date) => {
    // Format the date with day of the week, day, and month in Italian
    const formattedDate = dayjs(date).locale("it").format("dddd D MMMM") // Include day of the week, day, and month

    // Capitalize the first letter of the day of the week and month
    const capitalizeFirstLetter = (text) =>
      text.charAt(0).toUpperCase() + text.slice(1)

    // Split the formatted date to capitalize each part
    const [dayOfWeek, day, month] = formattedDate.split(" ")

    // Reassemble the formatted date with capitalization
    return `${capitalizeFirstLetter(dayOfWeek)} ${day} ${capitalizeFirstLetter(
      month
    )}`
  }

  const handleCheckboxChange = () => {
    setValues((prevValues) => ({
      ...prevValues,
      doubleGroup: !prevValues.doubleGroup,
      ...(prevValues.doubleGroup && {
        titleG2: "",
        placeG2: "",
        hourG2: "",
        meetingHourG2: "",
        opponentsG2: "",
        selectedPlayersG2: [],
        numMatchesG2: 0,
      }),
    }))
  }

  const makeConvocation = () => {
    const sortedPlayers = values.selectedPlayers?.slice().sort()
    const selectedType = typeItems.find((item) => item.id === values.type)

    setText(
      `${values.title.toUpperCase()}
📅  ${formatDateWithCapitalMonth(values.date)}

📍 ${values.place}

⏱ Ritrovo ore ${values.meetingHour}
⚽️🔛 ${
        values.type === "Torneo" ? "Prima partita calcio" : "Calcio"
      } d’inizio ore ${values.hour}
${values.opponents ? `🆚 ${values.opponents}` : ""}
${
  values.meal
    ? `
🍝 I ragazzi mangeranno con noi al costo di ${values.meal}€, mettere i soldi nel borsone li ritiriamo noi
`
    : ""
}
${sortedPlayers.map((player) => `- ${player.player}`).join("\n")}

💡 OBBLIGATORIA divisa di rappresentanza JAcademy 
👉🏼 Mettere nel borsone: divisa allenamento corta con calzettoni, kit lungo allenamento, termica, k-way, borraccia personale, parastinchi ed entrambe le scarpe (calcio e calcetto)

Grazie
Buona serata 🙋🏻‍♂️`
    )
  }

  const makeConvocationG2 = () => {
    const sortedPlayers = values.selectedPlayers?.slice().sort()
    const sortedPlayersG2 = values.selectedPlayersG2?.slice().sort()
    const selectedType = typeItems.find((item) => item.id === values.type)

    setText(
      `${selectedType.title.toUpperCase()}
📅  ${formatDateWithCapitalMonth(values.date)}

${values.title.toUpperCase()}

⏱ Ritrovo ore ${values.meetingHour}
⚽️🔛 ${
        values.type === "Torneo" ? "Prima partita calcio" : "Calcio"
      } d’inizio ore ${values.hour}
📍 ${values.place}
${values.opponents ? `🆚 ${values.opponents}` : ""}
${
  values.meal
    ? `
🍝 I ragazzi mangeranno con noi al costo di ${values.meal}€, mettere i soldi nel borsone li ritiriamo noi
`
    : ""
}
${sortedPlayers.map((player) => `- ${player.player}`).join("\n")}


${values.titleG2.toUpperCase()}

⏱ Ritrovo ore ${values.meetingHourG2}
⚽️🔛 ${
        values.type === "Torneo" ? "Prima partita calcio" : "Calcio"
      } d’inizio ore ${values.hourG2}
📍 ${values.placeG2}
${values.opponentsG2 ? `🆚 ${values.opponentsG2}` : ""}
${
  values.meal
    ? `
🍝 I ragazzi mangeranno con noi al costo di ${values.meal}€, mettere i soldi nel borsone li ritiriamo noi
`
    : ""
}
${sortedPlayersG2.map((player) => `- ${player.player}`).join("\n")}

💡 OBBLIGATORIA divisa di rappresentanza JAcademy 
👉🏼 Mettere nel borsone: divisa allenamento corta con calzettoni, kit lungo allenamento, termica, k-way, borraccia personale, parastinchi ed entrambe le scarpe (calcio e calcetto)

Grazie
Buona serata 🙋🏻‍♂️`
    )
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: { xs: 1, sm: 2 },
      }}
    >
      <ToastContainer />
      {isMobile ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <IconButton onClick={() => navigate("/matches")}>
            <ArrowBackIcon />
          </IconButton>
          <Button variant="contained" color="primary" onClick={save}>
            Salva
          </Button>
        </Box>
      ) : null}
      <Paper
        elevation={5}
        sx={{
          padding: { xs: 2, sm: 4 },
          borderRadius: 3,
          width: "100%",
        }}
      >
        <Controls.Text variant="h5" gutterBottom text="Dati Impegno" />
        <Tooltip title="Doppio Gruppo">
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={values.doubleGroup}
              onChange={handleCheckboxChange}
              inputProps={{ "aria-label": "Doppio Gruppo" }}
            />
            <span>Doppio Gruppo</span>
          </div>
        </Tooltip>

        <FormComponent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Controls.DateInput
                name="date"
                label="Data"
                value={values.date}
                onChange={handleDateChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Select
                name="type"
                label="Tipo"
                value={values.type}
                onChange={handleInputChange}
                options={typeItems}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="title"
                label={values.doubleGroup ? "Titolo Gruppo 1" : "Titolo"}
                value={values.title}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="place"
                label={values.doubleGroup ? "Luogo Gruppo 1" : "Luogo"}
                value={values.place}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="hour"
                label={values.doubleGroup ? "Ora Gruppo 1" : "Ora"}
                value={values.hour}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="meetingHour"
                label={
                  values.doubleGroup ? "Ora Ritrovo Gruppo 1" : "Ora Ritrovo"
                }
                value={values.meetingHour}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="opponents"
                label={values.doubleGroup ? "Avversari Gruppo 1" : "Avversari"}
                value={values.opponents}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="meal"
                label="Costo Pasto (opzionale)"
                value={values.meal}
                onChange={handleInputChange}
              />
            </Grid>
            {values.type === "Torneo" && (
              <Grid item xs={12} md={6}>
                <Controls.Input
                  name="numMatches"
                  label={
                    values.doubleGroup
                      ? "Numero Partite Gruppo 1"
                      : "Numero Partite"
                  }
                  type="number"
                  value={values.numMatches}
                  onChange={handleInputChange}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Controls.MultipleSelect
                name="selectedPlayers"
                label={
                  values.doubleGroup
                    ? "Giocatori Selezionati Gruppo 1"
                    : "Giocatori Selezionati"
                }
                selectedPlayers={values.selectedPlayers.map(
                  (player) => player.id
                )}
                onChange={handleInputChange}
                options={playersOptions}
              />
              {/* Aggiungi la scritta sotto il MultipleSelect */}
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  marginTop: 1,
                  marginBottom: 2,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Giocatori Selezionati: {values.selectedPlayers.length}
              </Typography>
            </Grid>
            <Divider sx={{ margin: "20px 0" }} />
            {/* GRUPPO 2*/}
            {values.doubleGroup && (
              <>
                <Grid item xs={12} md={6}>
                  <Controls.Input
                    name="titleG2"
                    label="Titolo Gruppo 2"
                    value={values.titleG2}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.Input
                    name="placeG2"
                    label="Luogo Gruppo 2"
                    value={values.placeG2}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.Input
                    name="hourG2"
                    label="Ora Gruppo 2"
                    value={values.hourG2}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.Input
                    name="meetingHourG2"
                    label="Ora Ritrovo Gruppo 2"
                    value={values.meetingHourG2}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controls.Input
                    name="opponentsG2"
                    label="Avversari Gruppo 2"
                    value={values.opponentsG2}
                    onChange={handleInputChange}
                  />
                </Grid>
                {values.type === "Torneo" && (
                  <Grid item xs={12} md={6}>
                    <Controls.Input
                      name="numMatchesG2"
                      label="Numero Partite Gruppo 2"
                      type="number"
                      value={values.numMatchesG2}
                      onChange={handleInputChange}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Controls.MultipleSelect
                    name="selectedPlayersG2"
                    label="Giocatori Selezionati Gruppo 2"
                    selectedPlayers={values.selectedPlayersG2.map(
                      (player) => player.id
                    )}
                    onChange={handleInputChange}
                    options={playersOptions}
                  />
                  {/* Aggiungi la scritta sotto il MultipleSelect */}
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      marginTop: 1,
                      marginBottom: 2,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Giocatori Selezionati: {values.selectedPlayersG2.length}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <Controls.Button
                  text="Salva"
                  size="medium"
                  fullWidth
                  sx={{
                    backgroundColor: "primary.main",
                    "&:hover": {
                      backgroundColor: "primary.dark",
                    },
                  }}
                  onClick={save}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <Controls.Button
                  text="Crea Convocazione"
                  size="medium"
                  fullWidth
                  sx={{
                    backgroundColor: "success.main",
                    "&:hover": {
                      backgroundColor: "success.dark",
                    },
                  }}
                  onClick={
                    values.doubleGroup ? makeConvocationG2 : makeConvocation
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <Controls.Button
                  text="Elimina"
                  size="medium"
                  fullWidth
                  color="error"
                  onClick={() => setOpen(true)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "error.dark",
                      color: "#fff",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </FormComponent>
      </Paper>
      <Modal
        open={open}
        setOpen={setOpen}
        title="Conferma Eliminazione"
        content="Sei sicuro di voler eliminare questa partita?"
        onConfirm={deleteMatch}
      />
    </Box>
  )
}

export default MatchForm
